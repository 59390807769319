// @file
// SASS partials for Menu Block styles
// Used just for Menus added via Menu Block.

// Bootstrap 3
// Give active child links in a menu a different style than the parent item
.nav-pills > li.active > ul {
  a.active {
    background: $uklightweight;
    color: $brand-primary;
  }
}

// Left-side border for submenus
ul.menu {
  > li > ul.menu {
    border-left: 2px solid #eee;
    padding-left: 5px;
  }
}

// If the menu allows for collapsed submenus, format the chevron
i.cafe-sub-menu {
  position: absolute;
  right: 5px;
  top: 13px;
}
