.blog-post-byline {
	padding-bottom: 1em;
}
.blog-post-authors {
	padding-right: 4px;
}
#content .blog-post-authors a {
	text-decoration: none;
}
.field-name-field-k-cafe-blog-featured-image .field-items .field-item {
	border: 0;
	padding: 0;
	border-radius: 0;
	margin: 0 0 1em 0;
}
.blog-teaser .field-name-field-k-cafe-blog-featured-image .field-items .field-item {
	float: left;
	margin: 0 1em 0 0;
}
.field-name-field-k-cafe-blog-image-caption {
	margin-top: -1em;
}
#content .blog-list {
	li {
		padding-bottom: 1.5em;
		border-bottom: 1px solid #eee;
		&:last-of-type {
			border-bottom: none;
		}
	}
}
#content .blog-list-item-condensed {
	padding: 1em 0;
	&:first-of-type {
		padding-top: 0;
	}
}
#content .blog-list-authors, #content .blog-list-categories {
	margin-bottom: 2em;
	.item-list ul li {
		padding: 4px 0;
		margin: 0;
		font-size: 13px;
		a {
			text-decoration: none;
			font-size: 16px;
		}
	}
}
