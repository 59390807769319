// @file
// SASS partials for Overall CAFE Boostrap 3 styles
body {
  font-size: $basefont;
  font-family: $font-sans-serif;
  color: $body_color;
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $font-sans-serif;
  font-weight: $headings-font-weight;
}

a {
  color: $blue_medium;
  &:hover {
    color: $blue_light;
  }
}
.btn-primary {
  background-color: $blue_medium;
  border-color: $blue_medium;
  &:hover {
    background-color: $blue_light;
    border-color: $blue_light;
  }
}

// Add some additional styling to hyperlinks in the content div
#content {
  a {
    // text-decoration: underline;
    // font-weight: 700;
  }
  // We don't need the underlines in menus or tabs
  .menu a, .nav a, .vertical-tabs-list a, a.btn {
    text-decoration: none;
  }
}

.uk-global-search {
  .btn {
    //margin-top: -3px;
    //margin-left: -4px;
    //padding: 2px 14px 2px 14px;
    //border-top-left-radius: 1px;
    //border-bottom-left-radius: 1px;
    &:hover,
    &:focus {
      color: $blue_medium;
      text-decoration: none;
      background-color: #fff;
    }
  }
  .btn-primary {
    color: $gray_medium;
    background-color: $wildcat_white;
    border-color: $light_gray;
    height: 36px;
  }
}

  .uk-global-search .btn-primary:hover, .uk-global-search .btn-primary:focus,
  .uk-global-search .btn-primary:active, .uk-global-search .btn-primary.active,
  .uk-global-search .btn-primary.disabled, .uk-global-search .btn-primary[disabled] {
    color: $blue_medium;
    //background-color: #0044cc;
  }

  .views-row-even.k-job-listings {
    background-color: #F2F2F2;
  }

  .job-listings-padding {
    padding: 0px 10px;
  }

  hr#footer-separator {
    width: 90%;
    margin: 10px auto;
    border-top: 1px solid #c0c0c0;
  }

  #page-footer .site-footer ul {
    padding-left: 0px;
  }

  .calendar-calendar {
    padding-left: 0;
    padding-right: 0;
  }

  .calendar-calendar .month-view table {
    border-top: 1px solid #ccc;
  }

  /* Modified event colors for better display */
  .calendar-calendar .month-view .full td.multi-day div.monthview,
  .calendar-calendar .week-view .full td.multi-day div.weekview,
  .calendar-calendar .day-view .full td.multi-day div.dayview {
    background: #ccccff;
    color: #4f4fb0;
  }

  .calendar-calendar .month-view .full td.multi-day .inner .monthview .continues,
  .calendar-calendar .month-view .full td.multi-day .inner .monthview .cutoff,
  .calendar-calendar .week-view .full td.multi-day .inner .weekview .continues,
  .calendar-calendar .week-view .full td.multi-day .inner .weekview .cutoff {
    background: #ccccff;
  }

  /* Modified Prev & Next buttons' style for better display */
  .view .date-nav-wrapper .date-prev {
    border-radius: 5px 5px 5px 5px;
  }

  .view .date-nav-wrapper .date-next {
    border-radius: 5px 5px 5px 5px;
  }

  .view .date-nav-wrapper .date-next a {
    margin-left: 10px;
  }

  .view .date-nav-wrapper .date-prev a {
    margin-right: 10px;
  }

  /* Move Prev & Next buttons for better display */
  .view .date-nav-wrapper .date-prev {
    right: auto;
    left: -18px;
  }

  .cafe-home-page .item-list ul {
    list-style-type: none;
  }

  /* Taken from several views in the templates directory */
  .view-id-view_k_administration .views-row-odd,
  .view-id-view_k_directory .views-row-odd,
  .view-id-view_k_field_directory .views-row-odd,
  .view-id-views_k_faculty_staff .views-row-odd,
  .view-id-views_k_people .views-row-odd,
  .view-id-people_k_job_group .views-row-odd {
    background-color: #FFF;
    margin-bottom: 5px;
  }

  .view-id-view_k_administration .views-row-even,
  .view-id-view_k_directory .views-row-even,
  .view-id-view_k_field_directory .views-row-even,
  .view-id-views_k_faculty_staff .views-row-even,
  .view-id-views_k_people .views-row-even,
  .view-id-people_k_job_group .views-row-even {
    background-color: #f3f4ee;
    margin-bottom: 5px;
  }

  /* Taken from views-view-unformatted--view-k-hes-directory.tpl.php */
  .view-id-view_k_hes_directory .span12{
    margin-left: 0px;
    border: solid 1px #ebebeb;
  }

  .view-id-view_k_hes_directory .span8{
    padding-top: 5px;
  }

  /* Taken from note-content-type-k-kpp-commodity.tpl.php */
  div.span12statements {
    border-top: inset;
    padding-top: 4px;
  }

  /* Taken from node--content-type-k-kpp-recipe.tpl.php */
  div.span12commodity {
    font-variant: small-caps;
    font-size: 20px;
    text-align: center;
    box-shadow: 2px 9px 10px 0px rgba(128,125,128,1);
    padding-bottom: 10px;
  }

  div.span12kpp {
    padding-top: 20px;
  }

  div.span12date {
    font-weight: bold;
  }

  div.span12statements {
    border-top: inset;
    padding-top: 4px;
  }

  .ui-widget-header .ui-button-icon-only .ui-icon {
    top: 25%;
    left: 29%;
  }

  .ui-widget-header button.ui-dialog-titlebar-close {
    margin-top: 8px;
    float: right;
  }

  .college-home-message {
    /*'Muli', sans-serif; 'Lora', serif;*/
    font-family: 'Lora', serif;
    font-weight: 400;
    font-style: italic;
    color: white;
    font-size: 28px;
    line-height: 50px;
    text-shadow: 1px 1px 0px #000, 2px 2px 10px #000;
    padding-left: 15px;
  }

// Taken from cafe-4h-guide.css
.text4h {
  color: #339966;
}
