// Styles for panels and panes

// Styles for Shareable GIF fieldable panel pane

figure.shareable-gif__background-white {
    background-color: #fff;
    margin: 1em 0;
}

figure.shareable-gif__background-wildcat-blue {
    background-color: #0033a0;
    margin: 1em 0;
}

figure.shareable-gif__background-midnight-blue {
    background-color: #041E42;
    margin: 1em 0;
}

figure.shareable-gif__background-transparent {
    background: #a9a9a9;
    background-image: linear-gradient(45deg, rgba(0,0,0,.25) 25%,
        transparent 0, transparent 75%, rgba(0,0,0,.25) 0),
        linear-gradient(45deg, rgba(0,0,0,.25) 25%,
        transparent 0, transparent 75%, rgba(0,0,0,.25) 0);
    background-position: 0 0, 25px 25px;
    background-size: 50px 50px;
    margin: 1em 0;
}

figcaption.shareable-gif__caption {
    background-color: #041E42;
    padding: 1em;
    text-align: center;
    overflow: hidden;
}

@media only screen and (max-width: 991px) {
    .shareable-gif__button {
        margin-bottom: 0.5em !important;
        }
}

.shareable-gif__button {
    width: 100% !important;
}

.shareable-gif__button i.fa {
    padding-right: 0.5em !important;
}