.view-id-county_data_profiles.view-display-id-cp {
  div div {
    width: 105px;
    display: inline-block;
    border: 1px solid #000;
    padding: 2px;
    text-align: center;
    margin-left: -1px;
    margin-bottom: -1px;
  }
}
