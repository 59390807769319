@media print {
  // Print overrides for Field Directory
  // Note: We do need to think about overarching print rules for other sites as well
  .page-print-friendly {
    .college-block,
    .item-list,
    .uk-footer,
    .uk-global-header,
    .views-exposed-form,
    h1.title,
    img {
      display: none;
    }

    .title {
      font-size: 12px !important;
      font-weight: bold;
    }
  }
}
