// @file
// SASS partials for Footer styles

#page-footer {
  font-size: 90%;
  font-weight: lighter;
  font-variant: normal;
  margin-top: 6em;
  > div {
    @media #{$bootstrap_phones_to_tablets} {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .band {
    width: 100%;
    background: $gray_light3;
    margin-top: 0px;
    border-top: 1px solid $gray_light2;
  }
  .uk-footer {
    background: $blue_medium;
    color: $gray_light;
    a {
      color: #fff;
    }
  }

  .uk-footer-hr {
  }
  @media #{$tablet} {
    .uk-footer-lr {
    }
  }

  @media #{$tablet} {
    .padfoot {
      padding-left: 20px;
    }
  }

  .site-footer {
    padding: 1em 0;
    ul {
      margin: 0px;
      li {
        list-style: none;
        margin-top: 5px;
        line-height: 16px;
        padding-bottom: 5px;
        &:last-child {
          padding-bottom: 2em;
        }
        @media #{$bootstrap_phones_to_tablets} {
          //text-align: center;
        }
      }
    }
  }

  .cafe-dept-info {
    background-color: $collegewhite;
    padding: 2em 0 2em 0;
    border-top: 1px solid $gray_light2;
    @media #{$bootstrap_phones_to_tablets} {
      padding-left: 15px;
      padding-right: 15px;
    }
    a {
      color: $blue_medium;
    }
  }

  .site-footer, .cafe-dept-info {
    a {
      &.section {
        margin-bottom: 1em;
        display: block;
        color: $blue_medium;
      }
      @media #{$bootstrap_phones_to_tablets} {
        //text-align: center;
      }
    }
    .dept-info-container {
      border-left: 1px solid #eee;
    }
  }

  .site-footer {
    a {
      color: $blue_medium;
    }
  }

  .department-block {
    padding: 1em 0;
    border-right: 1px solid $gray_light2;
    @media #{$phone} {
      border-right: none;
      border-bottom: 1px solid $gray_light2;
    }
  }
  .college-block {
    padding: 1em 0;
    .college-links {
      line-height: 2em;
    }
  }
  .social {
    text-align: left;
    margin-bottom: 5px;
    margin-right: 15px;
    margin-left: -5px;
    padding: 0.25em 0;
    a {
      color: $blue_medium;
      font-size: 8px;
      font-weight: lighter;
      background: none;
      &:hover {
        text-decoration: none;
        color: $blue-light;
      }
    }
    .give-now {
      font-size: 12px;
      text-transform: uppercase;
      margin-left: 3em;
      margin-top: -20px;
      background: $blue-medium;
      color: $wildcat_white;
      &:hover {
        background: $blue-light;
        color: $wildcat_white;
      }
    }
    @media #{$phone} {
      text-align: center;
      .give-now {
        margin-left: 0;
        margin-top: 0;
      }
    }
  }

  .site-footer .site-name-container {
  }

  .cafe-dept-info .site-name-container {
    color: $dark_blue;
  }

  .site-footer .site-contact-info {
  }

  .site-name-container {
    margin: 0;
    color: $blue_medium;
    .site-name-prefix, .site-name-suffix {
      line-height: 15px;
      min-height: 15px;
      font-size: 15px;
      color: $gray_dark;
    }
    .site-name-primary {
      font-size: 16px;
      line-height: 18px;
    }
    .site-name {
      text-shadow: none;
    }
  }

  .footer-close {
    text-align: center;
    font-size: .8em;
    padding-bottom: 1em;
    ul {
      list-style: none;
      padding: 0;
      li {
        display: inline-block;
        padding: 0 1em;
        @media #{$bootstrap_phones_to_tablets} {
          display: block;
          margin-bottom: 0.5em;
        }
      }
    }
    .col-sm-2 {
      text-align: center;
      //@include box-sizing(border-box);
      //border-right: 1px solid $barseperator;
      a {
        font-size: inherit;
      }
    }
  }
}
