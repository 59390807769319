.field-name-field-k-308-venomous-status {
  font-size: 150%;
}
.field.field-name-field-k-308-ky-geo-range.field-type-image.field-label-hidden {
  margin: 10px 0 0 0;

}

body.node-type-content-type-308-snake .field {
  //margin: 0 10px;
}

.field-name-field-k-308-venomous-status {
  font-size: 300%;
  line-height: 100%;
}

div.col-xs-12[data-cafe-multi-image-style=image_style_k_308_snake] {
  text-align: center;
  margin: 5px auto;
}

.image-style-k-308-snake, .k-308.snake-range {
  width: 100%;
}

.cafe-multi-image-subs .image-style-k-308-snake {
  margin-bottom: 5px;
}

article.node-content-type-308-snake .field.field-type-image .field-item {
  display: block;
}

div.field-name-field-k-308-snake-sup-images img.image-style-k-308-snake {
        width:auto;
}

div.field-name-field-k-308-snake-sup-images div.field-item.odd, div.field-name-field-k-308-snake-sup-images div.field-item.even {
  text-align: center;
}
