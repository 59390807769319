// Contains SCSS Variables for the College of Agriculture, Food, and Environment


// Colors from COA Bootstrap theme
$dark_blue: #1a4569;
$tan: #dacebc;
$uk_blue: #005DAA;
$light_blue: rgba(36, 107, 167, .7);
$green: #547150;
$light_gray: #F1F1F1;
$dim_gray: #696969;
$barseperator: #2E597D;
$collegewhite: #ffffff;
$rand_blue: #245e95;
$collegebrown: #544534;
$collegeyellow: #fbf8d7;

$bootstrap_large_display: "(min-width: 1200px)";
$bootstrap_default: "(min-width: 980px)";
$bootstrap_portrait_tablets: "(min-width: 768px) and (max-width: 979px)";
$bootstrap_phones_to_tablets: "(max-width: 767px)";
$bootstrap_phones: "(max-width: 480px)";


// Colors from April 2008 version of http://www.uky.edu/Graphics/WebGraphicStandards.pdf on Page 7
$ukblue: #005daa;
$ukblue_complement: #00305e;

$ukmidweight: #6b89b4;
$ukmidweight_complement: #b9c5dc;

$uklightweight: #d7dcea;
$uklightweight_complement: #bdc5dc;

$ukgold: #ffe7a7;
$ukgold_complement: #fff1d0;

$ukblack: #000;
$ukgray: #999;


////////////////////////////////////////////////////////////////////////////

// From compass_twitter_bootstrap/_scaffolding.scss
$baseLineHeight: 20px;

$ukToolBarHeight: 44px;
$ukToolBarLineHeight: 40px;


//Typography & EMs
$basefont: 16px;
$baseh1: 30;
$font-sans-serif: 'Muli', 'Helvetica Neue', Helvetica, sans-serif, sans-serif;
$font-serif: 'Lora', serif;
$headings-font-weight: 700;

$large-desktop: "(min-width:1200px)";
$desktop: "(min-width:1024px)";
$tablet: "(min-width:768px)";
$landscape-phone: "(min-width:481px) and (max-width:767px) ";
$phone: "(max-width: 480px)";


// Colors from http://webgov.uky.edu/standards - June 2017
$body_color: darken($gray, 6%);

// Primary Color Palette
$wildcat_blue: #0033A0;
$wildcat_white: #ffffff;
$wildcat_black: #2C2A29;

// Secondary Color Palette
$blue_light: #1897D4;
$blue_medium: #143887;
$blue_dark: #202C5F;

$gray_light: #C8C8C7;
$gray_medium: #A5ACAF;
$gray_dark: #63656A;
$gray_light2: rgba($gray_light, 0.5);
$gray_light3: rgba($gray_light, 0.2);

$brand-primary: $blue_light;
