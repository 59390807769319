// Adjustments to widget previews for panels on landing pages
// Based on panopoly-modal.css styles
#modal-content {
  fieldset {
    clear: none;
    width: auto;
    position: relative;
    top: 12px;
    margin-top: 10px;
    margin-bottom: 32px;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding: 0 0 10px;
  }
  .widget-preview {
    font-size:90%;
    legend {
      padding: 10px;
      background: $uklightweight;
    }
    .fieldset-wrapper {
      padding:5px;
      max-height: 200px;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .fieldset-legend {
      font-size: 12px;
      font-weight: bold;
    }
  }
  .widget-preview-single {
    &.widget-preview-single {
      margin-top: 0;
    }
    .fieldset-wrapper {
      max-height: 400px;
    }
  }
  .ctools-auto-submit-full-form {
    .form-actions {
      width: 48%;
      max-width: none;
    }
    .vertical-tabs {
      clear: both;
    }
  }
}

// Make the close button more prominent
.modal-header .close {
  font-size: 30px;
  color: #FF0000;
}

.form-actions {
  padding: 19px 20px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: whitesmoke;
  border-top: 1px solid #e5e5e5;
}

// Fix for Media Browser modal
.ui-front {
  z-index: 1001 !important;
}

// Fix for radio buttons overlapping labels in some modals
#modal-content .view-settings-wrapper .form-radio {
  margin-left: -20px;
}
