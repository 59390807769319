// Styles for Nodes
// -----------------------------------------------------------------------------

// Utility styles for reordering event node elements

@media only screen and (min-width: 768px) {
    .event__list-group-mobile {
        display: none;
    }

    .event__list-group-desktop {
        display: block;
    }
}

@media only screen and (max-width: 767px) {
    .event__list-group-mobile {
        display: block;
    }

    .event__list-group-desktop {
        display: none;
    }
}