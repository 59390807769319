// @file
// Overrides for the CAFE News Site


/*Content Pane CAFE News Featured Story*/

.cafenews-authoring {
  font-style: italic;
  line-height: 1.35em;
  font-size: 2rem;
  padding: 10px 0px 10px 0px;
}

.field.field-name-field-k-cafe-news-ft-m-caption {
  background: #f0efee;
  padding: 18px;
}

/*Pane Title*/
#cafenews-featured-hero .cafenews-title {
  font-size: 32px;
}

/*Pane Teaser*/
#cafenews-featured-hero .cafenews-teaser {
  font-size: 16px;
  padding: 0 0 5px;
}


/*Categories Content Pane CAFE News News Topics*/
.cafenews-topics .view-header {
  margin-top: -20px;
}


/*Content Pane CAFE News Archive Article*/

.cafenews-article .cafenews-featured-media {
  max-width: 100%;
}

.cafenews-article .cafenews-featured-title {
  padding-bottom: 30px;
}


/*Content Pane CAFE News Archive Archive Video */

div[id="recent-video-news"] {
  background-color: $wildcat_white;
  /*color: #fff;*/
  /*padding: 0 0 10px 0;*/
  /*margin: 0px 15px 30px;*/
  display: block;
  margin: 0px;
}

.field-name-field-k-cafe-news-featured-media .file-video-youtube iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /*height: 100%;*/
}

#recent-video-news .container {
  background-color: $wildcat_white;
}

.pane-views-k-cafe-news-content-pane-cafe-news-archive-video {
  margin-left: -15px;
}


/*Content Pane CAFE News Home Page Latest*/

.pane-views-k-cafe-news-content-pane-cafe-news-home-page-latest .pane-title {
  font-size: 28px;
  border-bottom: 4px solid $gray_light2;
  padding-bottom: 5px;
  margin-bottom: 20px;
  margin-top: 0;
}

/*Pane*/

.cafe-news-home-page-latest #latest-news {
  padding-left: -15px;
}


/*Pane Fields*/

#cafenews-latest .cafenews-featured-title {
  color: $ukblack;
  text-decoration: none;
  padding-bottom: 12px;
  border-bottom: 1px solid $light_gray;
  margin: 15px 0 12px;
}


/*Content Pane CAFE News Latest*/
/*Side Column Field Styling*/

.sidebar-group h2 {
  font-size: 28px;
  border-bottom: 4px solid $gray_light2;
  padding-bottom: 5px;
  margin-bottom: 10px;
  margin-top: 0;
}


.view-display-id-content_pane_news_topics {
  margin-top: 25px;
}

/*Content Pane CAFE News Latest Audio News*/

.pane-views-k-cafe-news-content-pane-cafe-news-latest-audio-news .pane-title {
  font-size: 28px;
  border-bottom: 4px solid $gray_light2;
}

/*Button*/
.cafe-news-latest-audio .btn {
  margin-top: 20px;
}

/*Content Pane CAFE News Latest Video News*/

div[id="cafenews-recent-video"] {
  background-color: $collegewhite;
  ;
  padding: 0px;
}


.field-name-field-k-cafe-news-featured-media .file-video-youtube iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /*height: 100%;*/
}

#cafenews-recent-video .container {
  background-color: $wildcat_white;
}

.pane-views-k-cafe-news-content-pane-cafe-news-latest-video-news .pane-title {
  font-size: 28px;
  border-bottom: 4px solid $gray_light2;
}

/*Button*/
.cafe-news-latest-video .btn {
  margin-top: 20px;
}


/*Content Pane CAFE News Recent News*/
/*Pane Title*/

.pane-views-k-cafe-news-content-pane-cafe-news-recent-news .pane-title {
  font-size: 28px;
  border-bottom: 4px solid $gray_light2;
}


/*Button*/
.cafe-news-recent-featured .btn {
  margin-top: 20px;
}
/*Page Archive*/
.view-grouping-header {
  font-size: 36px;
}

/*Non-templated Views - Archive Articles Main*/
.view-display-id-content_pane_cafe_news_archive_main_article {
  .views-field-created {
    padding: 5px 0;
    margin: 0;
  }

  .views-field-title {
    font-size: 32px;
  }
}


/*Non-templated Views - Archive Audio*/
.view-display-id-content_pane_cafe_news_archive_audio {
  .views-field-created {
    padding: 5px 0;
    margin: 0;
  }
  .views-field-title {
    font-size: 24px;
  }
}

/*Non-templated Views - Archive Video*/
.view-display-id-content_pane_cafe_news_archive_main_video {
  .views-field-created {
    padding: 5px 0;
    margin: 0;
  }

  .views-field-title {
    font-size: 32px;
  }
}

/*Node Content Type CAFE News*/
/*Link to YouTube field configuration*/
// adapted from https://css-tricks.com/NetMag/FluidWidthVideo/Article-FluidWidthVideo.php
.field-name-field-k-cafe-news-featured-media .file-video-youtube {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 30px;
  height: 0px;
  overflow: hidden;
  border: 20px;

  iframe {
    /*position: absolute;*/
    top: 0;
    left: 0;
    width: 100%;
    /*height: 100%;*/
  }
}

/*Main Column Field Styling*/

.cafenews-article-container {
  margin: -15px;
}

.field.field-name-field-k-cafe-news-teaser .field-item {
  margin-bottom: 5px;
}

/*Featured Media*/

.cafenews-body {
  padding-top: 15px;
}

.cafenews-featured-media.caption {
  font-style: italic;
  right: 20px;
  background-color: $gray_light2;
  margin-bottom: 15px;
}

.cafenews-terms .field_k_cafe_news_topics {
  padding-bottom: 40px;
}

/*Side Column Styling */

.view-display-id-content_pane_cafe_news_latest .views-field-title {
  padding-bottom: 25px;
}

// Override hyperlink styling to remove underline for some titles
#content .cafenews-title a,
#content .cafenews-featured-title a,
#content .views-field-title a {
	text-decoration: none;
}
