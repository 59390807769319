// @file
// SASS partials for Header styles

.uk-toolbar {
  .col-xs-12 a, .col-xs-12 a:visited, .col-xs-12 a:focus {
    color: #FFF;
  }

  .container .navbar div {
    padding-left: 0;
    padding-right: 0;
  }

  nav {
    display: inline-block;
  }

  width: 100%;
  background: $wildcat_black;

  .input-append, .input-append form {
    margin-bottom: 0px;
  }

  .input-append {
    padding: 5px 0px;
  }
}

.links, .links a {
  font-size: 13px;
  font-family: $font-sans-serif;
  line-height: 65px;
  padding-right: 15px;
  text-transform: uppercase;
  &:last-of-type {
    padding-right:0;
  }
  &:hover {
    text-decoration: none;
    color: $brand-primary;
  }
  &.inline {
    li {
      display: inline-block;
    }
  }
}

.banner {
  background-color: #f5f5f5;
  background-image: url('../images/cafe/site-banner-placeholder.jpg');
  background-position: top center;
  background-repeat: no-repeat;
  //background-size: cover;
  text-shadow: 1px 1px 0px #000, 2px 2px 10px #000;
}
// Site Banner is being placed on the row so the background image can flow
// underneath all the spans. As such we have to manually bump its left edge
// over to the width of the gutter Bootstrap's responsive breakpoints. But
// since we are hidding the entire row on Phones we don't have to worry about
// the fluid breaks, so we have just the default and large display.
.site-banner {
  padding-left:0;
  height: 100px;
  margin-top: -100px;
  > div {
    display: block;
    width: 100%;
    background: rgba(255,255,255,0.9);
    padding: 20px;
    border-radius: 4px;
  }
}


.site-name-container {
  display: inline-block;
  color: $blue_light;
  .site-name {
  }

  .site-name-prefix, .site-name-suffix {
    min-height: 15px;
    color: #ddd;
  }

  .site-name-prefix {
    float: left;
  }

  .site-name-primary {
    float: left;
    clear: both;
    font-size: 1.4em;
    padding: 0px;
    line-height: 1.1em;
    color: #fff;
    text-transform: uppercase;
  }
  .site-name-suffix {
    clear: left;
    float: right;
  }
}

.site-name-prefix, .site-name-suffix {
  color: $light_gray;
}

.college-byline {
  background-color: $rand_blue;

  .college-wordmark {
    background-image: ('../images/cafe/college-wordmark.png');
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    margin: auto;
    height: 100px;
    text-indent: -9999px;
    @media #{$bootstrap_portrait_tablets} {
      background-size: contain;
      // We are setting a width of 95% to give a little faux padding on the sides
      width: 158px;
    }
  }
  nav {
    text-align: center;
    background-color: $dark_blue;
    font-family: $font-serif;
    font-weight: 100;
    font-size: 15px;
    >a {color: $collegewhite; padding: 0px 2px;}
    @media #{$bootstrap_portrait_tablets} {
      font-size: 11px;
    }
  }
}

.navbar-inner {
  background-color: $collegewhite;
  background-position: 0% 0%;
  background-size: auto auto;
  background-repeat: repeat;
  background-origin: padding-box;
  background-clip: border-box;
  background-attachment: scroll;
  background-image: none;
  min-height: inherit;
}

div.college-logo {
  margin-top: 20px;
  margin-left: 5px;
  display:inline-block;
}
div.college-slogan {
  display:inline-block;
  font-family: $font-serif;
  color: white;
  text-transform: uppercase;
  font-size: 25px;
  line-height: 30px;
  text-shadow: 1px 1px 1px #000;
  margin-left:5px;
}

header.header {

}

/*
@media #{$tablet} {
  header.header {
    height: 185px;
  }
  .front header.header {
    height: 400px;
  }
  .front .site-banner {
    margin-top: -15px;
  }
}
*/

.uk-global-header {
  background-color: rgba($blue_medium, 1);

  .logo-container {
    padding: 8px 0 0 0;
    @media #{$bootstrap_phones_to_tablets} {
      text-align: center;
    }
  }
}

.uk-global-lockup--minmax {
  min-height:60px;
  min-width:264px;
  height: 50px;
  width: 333px;
}

.uk-global-search {
  //display: inline-block;
  //vertical-align: middle;
  margin-top:17px;
  margin-left:10px;
    .form-control {
      height: 36px;
    }
    .input-mini {
      //border: 2px solid #fff;
      -webkit-border-radius: 4px 0 0 4px;
      -moz-border-radius: 4px 0 0 4px;
      border-radius: 4px 0 0 4px;
      padding-left: 4px;
    }
}

.uk-global-subheader {
  padding: 1.5em 0;
  min-height: 110px;
}

.uk-toolbar.grayscale {
  background: #f6f6f6;
  height:auto;
  padding: 1px;

  .title {
    font-size: 30px;
    color: #666;
    text-transform: uppercase;
    font-family: $font-sans-serif;
  }
}

  form#searchbox_003398099011942252124\3a gmpps7ysdwm {
    margin:0px;
  }

  form#searchbox_003398099011942252124\3a gmpps7ysdwm.input-append{
    margin-bottom: 17px;
  }

  .uk-toolbar .links, .uk-toolbar .links a {
    font-size: 16px;
  }
  @media #{$bootstrap_phones_to_tablets} {
    .uk-toolbar .links, .uk-toolbar .links a {
      font-size: 13px;
      line-height: 42px;
    }
  }

  a.uk-global-header__myUK {
    display: block;
    background: url(../images/my-UK.png) center no-repeat ;
    background-size: contain;
    width: 60px;
    height: 60px;
    overflow: hidden;
    text-indent: -9999px
  }

  .uk-lockup-block {
    width: 300px;
    margin: 0 auto;
  }

  @media #{$bootstrap_default} {
    .uk-global-header .navbar .btn-navbar {
      display: inline-block;
      float: right;
    }
  }

  .navbar-toggle {
    margin-top: 0;
    margin-bottom: 0;
    float: inherit;
    width: 100%;
    color: white;
    padding: 0;
    text-align: center;
    .menu-button {
      display: inline-block;
      margin: 10px 0;
      font-size: 24px;
      line-height: 1.1;
    }
    &:hover,
    &:active {
      cursor: pointer;
      text-decoration: none;
    }
  }

  .navbar-toggle .icon-bar {

  }

  .uk-global-menu-band {
    margin-bottom: 1px;
    .navbar {
      margin-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      border: none;
    }
  }

  .navbar .nav {
    background: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    > li > a {
      float: none;
      padding: 1em 0.75em;
      color: $blue_medium;
      text-decoration: none;
      border-bottom: 4px solid transparent;
      font-weight: 100;
      @media #{$bootstrap_phones_to_tablets} {
        text-align: center;
      }
      @media #{$bootstrap_portrait_tablets} {
        padding: 1em 0.5em;
        font-size: 0.9em;
      }
    }
  }

  .navbar .nav > .active > a {
    color: $blue_medium;
    text-decoration: none;
    border-bottom: 4px solid $blue_medium;
  }

  .navbar .nav > li > a:focus,
  .navbar .nav > li > a:hover {
    color: lighten($blue_medium, 10%);
    text-decoration: none;
    background: none;
    border-bottom: 4px solid lighten($blue_medium, 10%);
  }

  .navbar .nav > .active > a,
  .navbar .nav > .active > a:hover,
  .navbar .nav > .active > a:focus {

  }

  /*
  @media (max-width: 992px) {
    #appendedInputButton {
      max-width: 105px;
    }
  }
  */

  @media #{$tablet} {
    #navigation.navbar {
      padding-left: 0;
      padding-right: 0;
    }
    .navbar-collapse {

      &.collapse {

      }
    }
    #main-menu {
      .navbar-nav {
        width: 100%;
        padding: 0.5em 0;
        li {
          float: none;
          display: inline-block;
        }
      }
    }
  }
  .navbar {
    min-height: auto;
  }
  @media #{$bootstrap_phones_to_tablets} {
    .uk-global-subheader {
      min-height: auto;
    }
    .navbar {
      margin-bottom: 0px;
    }

    .navbar-header {
      float: none;
    }

    .navbar-left,.navbar-right {
      float: none !important;
    }

    .navbar-toggle {
      display: block;
    }

    .navbar-collapse {
      border-top: 1px solid transparent;
      box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }

    .navbar-fixed-top {
      top: 0;
      border-width: 0 0 1px;
    }

    .navbar-collapse.collapse {
      display: none!important;
    }

    .navbar-nav {
      float: none!important;
      margin-top: 0;
      margin-bottom: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .navbar-nav>li {
      float: none;
    }

    .navbar-nav>li>a {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .collapse.in {
      display: block !important;
    }
  }
