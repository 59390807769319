// @file
// SASS partials for Breadcrumbs styles

div#breadcrumb,body > div.container.cafe-page-wrap > div.container.nav-collapse.nav-menu-collapse {
  background-color: #fff;
}
.nav.cafe-sub-menu { 
  margin-bottom: 0px; 
}
.nav-tabs > li {
  margin-bottom: 0px;
}
.cafe-menu-pad2 {
  .nav-tabs.nav-stacked {
    border-bottom: 0;
    border-top: 0;
  }  
  .nav-tabs.nav-stacked {
    &>li:first-child > a,&>li:last-child > a, {
      border-radius: 0;
    }
  }
}