// @file
// Overrides for the main college homepage

body.ca-main {
    .site-banner {
      display:none;
    }
    &.front {
      .uk-global-menu-band {
      }
      #main-menu {
        margin-top: 0;
        border: none;
      }
      .header-image {
          display: block;
          height: 400px;
          background: #fff url('http://photo.ca.uky.edu/gallery/main.php?g2_view=core.DownloadItem&g2_itemId=208066&g2_serialNumber=2') no-repeat center / cover;
          @media #{$bootstrap_phones_to_tablets} {
            height: 250px;
        }
      }
    }
}

.ca-mission {
  padding: 1.5em 2em;
  margin: 1px 0 3em 0;
  font-size: 1.1em;
  font-weight: 100;
  line-height: 1.8;
  background:$blue_medium;
  color:#fff;
  text-align:center;
  -webkit-box-shadow: 15px 15px 30px -10px rgba(0,0,0,0.4);
  -moz-box-shadow: 15px 15px 30px -10px rgba(0,0,0,0.4);
  box-shadow: 15px 15px 30px -10px rgba(0,0,0,0.4);
}

@media #{$bootstrap_phones_to_tablets} {
    body.ca-main .ca-mission {
        font-size: 1em;
        padding: 1em;
    }
}

// 2020 Homepage Redesign
// These are classes and styles initially created using vanilla css
// TODO: If this ends up staying around for long, we should restructure
// and take advantage of sass below

/* chad.css */
#page-footer {
  margin-top: 6em;
}
	.front #page-footer {
		margin-top: 0;
	}

.pane-title {
  font-family: "Nunito Sans";
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 0.5em;
}
.col-md-12.radix-layouts-content.panel-panel {
  padding-left: 0;
  padding-right: 0;
}


/* Utility classes */
.padded { padding: 1em; }
.padded--tb { padding-top: 1em; padding-bottom: 1em; }
.padded--lr { padding-left: 1em; padding-right: 1em; }
.padded--xl { padding: 2em; }
.padded--tb-xl { padding-top: 2em; padding-bottom: 2em; }
.padded--lr-xl { padding-left: 2em; padding-right: 2em; }

.border { border-style: solid; border-width: 1px; }
.border--lg { border-width: 3px; }
.border--xl { border-width: 6px; }
.border--blue { border-color: #143887; }
.border--gray { border-color: #ddd; }
.border--white { border-color: #fff; }
.border--midnight-blue { border-color: #041e42; }
.border--0 { border-width: 0; }

.row-aligned {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row wrap;
}
    .row-aligned::before {
        display: block;
    }

.dark-bg {
  color: #fff;
}
  .dark-bg a { color: #fff; }
  /* .dark-bg a:hover { }
  .dark-bg h1, .dark-bg h2, .dark-bg h3, .dark-bg h4, .dark-bg h5, .dark-bg h6 { } */

/* Remove rounded corners */
.rounded-0 { border-radius: 0; }

.hr--dashed {
  font-size: 1rem;
  line-height: 1rem;
  color: #000000;
  box-sizing: inherit;
  margin-top: calc(1.25rem - 1px);
  margin-bottom: 1.25rem;
  border: 1px dashed #c8c8c7;
  border-width: 0 0 1px;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
}

/* Links */
a.link--no-line {
  text-decoration: none;
}
a.link--editorial {
  box-sizing: inherit;
  background-attachment: scroll;
  background-image: linear-gradient(to top, rgba(161, 211, 237, 0.4) 0, rgba(161, 211, 237, 0.4) 0.5em, transparent 0.5em, transparent 110%), linear-gradient(to top, rgba(0, 51, 160, 0.4) 0, rgba(0, 51, 160, 0.4) 2px, transparent 2px, transparent 100%);
  background-repeat: no-repeat;
  background-position: 0 .5em, 0 0;
  position: relative;
  transition: .2s background-position ease-out;
  font-weight: 600;
  z-index: 1;
  text-decoration: none;
  color: #000000;
}
  a.link--editorial:hover {
    color: #0033a0;
    background-position: 0 0, 1000px 2px;
  }


/* Buttons */
.button {
  box-sizing: inherit;
  padding-top: calc(1rem - 2px);
  padding-right: calc(1.875rem - 3px);
  padding-bottom: calc(1rem - 4px);
  padding-left: calc(1.875rem - 3px);
  transition: .3s ease-in-out;
  border-width: 2px;
  border-style: solid;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Nunito Sans";
  display: inline-block;
  width: auto;
  margin: 0;
  border-color: #0033a0;
  background-color: #0033a0;
  color: #fff;
}
  .button:hover {
    border-color: #0033a0;
    background-color: #fff;
    color: #0033a0;
    text-decoration: none;
  }

.button--white {
  background-color: #fff;
  border-color: #0033a0;
  color: #0033a0;
}
  .button--white:hover {
    background-color: #0033a0;
    color: #fff;
  }

.button--fw {
  display: block;
}

.button--sm {
  font-size: 14px;
  line-height: 16px;
  padding-right: 23px;
  padding-left: 23px;
}
.button--lg {
  font-size: 18px;
  line-height: 24px;
  padding-right: 29px;
  padding-left: 29px;
}


/* Headline Groups */
.headline-group {
  color: #000000;
  box-sizing: inherit;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
}
  .headline-group__super {
    font-weight: normal;
    box-sizing: inherit;
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 16px;
    color: #515664;
  }
  .headline-group__head {

  }


/* Event Cards */
.event-card, .event-card h4 {
  font-family: "Nunito Sans";
}
  .event-card strong {
    font-weight: 600;
    text-decoration: none;
  }
  .event-card__body {
    padding: 15px;
    background-color: #fff;
  }
  .event-card a, .post-module a {
    box-sizing: inherit;
    background-attachment: scroll;
    background-image: linear-gradient(to top, rgba(161, 211, 237, 0.4) 0, rgba(161, 211, 237, 0.4) 0.5em, transparent 0.5em, transparent 110%), linear-gradient(to top, rgba(0, 51, 160, 0.4) 0, rgba(0, 51, 160, 0.4) 2px, transparent 2px, transparent 100%);
    background-repeat: no-repeat;
    background-position: 0 .5em, 0 0;
    position: relative;
    transition: .2s background-position ease-out;
    font-weight: 600;
    z-index: 1;
    text-decoration: none;
    color: #000000;
    line-height: 1.3;
  }
    .event-card a:hover, .post-module a:hover {
      color: #0033a0;
      background-position: 0 0, 1000px 2px;
    }

    /* Test */
    .post-module {
      position: relative;
      z-index: 1;
      display: block;
      background: #FFFFFF;
      min-width: 270px;
      height: 300px;
      /* -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15); */
      -webkit-transition: all 0.3s linear 0s;
      -moz-transition: all 0.3s linear 0s;
      -ms-transition: all 0.3s linear 0s;
      -o-transition: all 0.3s linear 0s;
      transition: all 0.3s linear 0s;
      font-family: "Nunito Sans";
			margin-bottom: 30px;
    }
    .post-module:hover,
    .hover {
      /* -webkit-box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3); */
    }
    .post-module:hover .post-module__thumbnail img,
    .hover .post-module__thumbnail img {
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      transform: scale(1.1);
      opacity: 0.6;
    }
    .post-module .post-module__thumbnail {
      background: #000000;
      height: 250px;
      overflow: hidden;
    }
    .post-module .post-module__thumbnail .date {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1;
      background: #143887;
      width: 55px;
      height: 55px;
      padding: 12.5px 0;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      border-radius: 100%;
      color: #FFFFFF;
      font-weight: 600;
      text-align: center;
      -webkti-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      line-height: 1;
      z-index: 2;
    }
    .post-module .post-module__thumbnail .date .day {
      font-size: 18px;
    }
    .post-module .post-module__thumbnail .date .month {
      font-size: 12px;
      text-transform: uppercase;
    }
    .post-module .post-module__thumbnail img {
      display: block;
      width: 120%;
      -webkit-transition: all 0.3s linear 0s;
      -moz-transition: all 0.3s linear 0s;
      -ms-transition: all 0.3s linear 0s;
      -o-transition: all 0.3s linear 0s;
      transition: all 0.3s linear 0s;
    }
    .post-module .post-content {
      position: absolute;
      bottom: 0;
      background: #FFFFFF;
      width: 100%;
      padding: 5px 0;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
      -moz-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
      -ms-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
      -o-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
      transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
      z-index: 2;
    }
    .post-module .post-content .category {
      position: absolute;
      top: -34px;
      left: 0;
      background: #143887;
      padding: 10px 15px;
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
    }
      .post-module .post-content .category a {
        color: #fff;
      }
    .post-module .post-content .title {
      margin: 0;
      padding: 0 0 10px;
      font-family: "Nunito Sans";
    }
    .post-module .post-content .sub_title {
      margin: 0;
      padding: 0 0 20px;
      color: #e74c3c;
      font-size: 20px;
      font-weight: 400;
      font-family: "Nunito Sans";
    }
    .post-module .post-content .description {
      display: none;
      color: #666666;
      font-size: 14px;
      line-height: 1.8em;
    }
    .post-module .post-content .post-meta {
      margin: 30px 0 0;
      color: #999999;
    }
    .post-module .post-content .post-meta .timestamp {
      margin: 0 16px 0 0;
    }
    .post-module .post-content .post-meta a {
      color: #999999;
      text-decoration: none;
    }
    .hover .post-content .description {
      display: block !important;
      height: auto !important;
      opacity: 1 !important;
    }


/* jared.css */
.btn, .media-widget > a, .btn-lg, .btn-group-lg > .btn, .media-widget.btn-group-lg > a {
	border-radius: 0;
}

.fancylink-container {
	padding-left: 2em;
}

a.fancylink {
	color: #143887;
	position: relative;
	text-decoration: none !important;
	transition: color .4s ease-out;
}

a.fancylink:hover {
	color: #457DFB;
	right: 0;
	text-decoration: none;
}

a.fancylink:before {
	position: absolute;
	content: "\f054";
	font-family: FontAwesome;
	color: #143887;
	transition: left .3s ease-in-out;
	left: -1.25em;
}

a.fancylink:hover:before {
	content: "\f054";
	font-family: FontAwesome;
	color: #143887;
	left: -1.00em;
}

nav#main-menu {
	background-color: #143887;
}

nav#main-menu div.primary-nav-links a {
	color: #fff;
	text-transform: none !important;
	font-size: 16px;
}

nav#main-menu div.primary-nav-links a:hover {
	color: #B1C9E8;
	text-decoration: underline;
	border-bottom: none !important;
}

nav#main-menu div.secondary-nav-links a {
	color: #fff;
	text-transform: none !important;
	font-size: 16px;
}

nav#main-menu div.secondary-nav-links a:hover {
	color: #B1C9E8;
	text-decoration: underline;
	border-bottom: none !important;
}


nav#main-menu ul {
	background-color: #143887;
	text-transform: none !important;
}

nav#main-menu li {
	background-color: #143887;
}

.secondary-nav-links {
	text-align: right;
}

.homepage-logo-container {
	padding: 1em 0 1em 0.25em;
	margin: 1.25em 0 -1em 0;
	overflow: visible;
	z-index: 1;

	@media #{$bootstrap_phones_to_tablets} {
		text-align: center;
		align: center;
	}
}

.homepage-uk-global-lockup--minmax {
	max-width: 95%;
	width: 95%;
}

.search__form {
  display: -webkit-box;
  display: flex;
}

.search--swap .search__input {
  -webkit-box-ordinal-group: 2;
          order: 1;
}
.search--icon .search__form {
  position: relative;
}
.search--icon .search__btn-submit {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  background: none;
}
.search--icon .search__btn-submit:focus, .search--icon .search__btn-submit:active, .search--icon .search__btn-submit:hover {
  box-shadow: none;
}
.search--icon .search__btn-submit:focus:before, .search--icon .search__btn-submit:active:before, .search--icon .search__btn-submit:hover:before {
  border-color: transparent;
}
.search--icon .search__btn-submit:focus:after, .search--icon .search__btn-submit:active:after, .search--icon .search__btn-submit:hover:after {
  background-color: transparent;
}
.search--icon .search__btn-submit:before, .search--icon .search__btn-submit:after {

}
.search--icon .search__btn-submit:before {
  right: 8px;
  top: 8px;
  width: 16px;
  height: 16px;
  border-radius: 0;
}
.search--icon .search__btn-submit:after {
  right: 30px;
  top: 23px;
  width: 3px;
  height: 16px;
  background-color: #fff;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.search--icon .search__btn-submit:hover {
  background: none;
	color: #B1C9E8;
}
.search--icon-expandable .search__input {
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: right center;
          transform-origin: right center;
  -webkit-transition: -webkit-transform 300ms ease-out;
  transition: -webkit-transform 300ms ease-out;
  transition: transform 300ms ease-out;
  transition: transform 300ms ease-out, -webkit-transform 300ms ease-out;
}
.search--icon-expandable .search__input:focus, .search--icon-expandable .search__input:active {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
.search--icon-expandable .search__btn-submit {
  background-color: #143887;
}
.search--icon-expandable .search__btn-submit:hover {
  background-color: #143887;
}
.search--expanded .search__input {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
.search__input:focus, .search__input:active, .search__btn-submit:focus, .search__btn-submit:active {
  outline: 0;
}
.search__input {
	margin-top: 10px;
  padding: 5px;
  width: 100%;
	height: 40px;
  color: #000;
  background-color: #fff;
  border: 0;
	border-radius: 0;
}
.search__btn-submit {
	margin-top: 10px;
  position: relative;
  padding: 5px;
	font-size: 85%;
  color: #fff;
  background-color: #143887;
  border: 0;
  cursor: pointer;
  z-index: 10;
  -webkit-transition: background-color 200ms ease-out;
  transition: background-color 200ms ease-out;
}

input.form-submission-button{
	visibility: hidden;
	width: 5px;
	height: 5px;
}
