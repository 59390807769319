@import "../fonts/cafe/cafe-social/font-face.css";

.social-icon:before {
  // You can be more comfortable with increased icons size
  //font-size: 32px;

  // Set the font color.
  //color: #000;

  font-family: "cafe-social";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;

  // fix buttons height, for twitter bootstrap
  line-height: 1.1em;

  // Font smoothing. That was taken from TWBS
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // Use the data-icon attribute to set which icon to show
  content: attr(data-icon);
}