// @file
// SASS partials for College Spotlight Hero Rotator.


//
// Static Row Styles
//

// Fix for issue #117, short H3 text on spotlight results in H3 text breaking
// out of the shade white background. To compensate we remove the width and
// max-width values, add 1em of right margin to the label, and make the width
// of the wrapper 100% so the descrption and h3 shading take up the full width
// of the image.

.pane-bundle-spotlight {
   .panopoly-spotlight-wrapper {
    width: 100%;
  }

  .panopoly-spotlight-label {
    width: auto;
    max-width: none;
    margin-right: 5em;
  }
}

div.college-spotlight-static-row-item {
  float: left;
  padding: 5px;
  @include box-sizing(border-box);

  img {
    @include box-shadow(0px 3px 3px rgba(50,50,50,0.75));
  }

  a {
    font-size: 16px;
    line-height: 16px;
    color: $blue_medium;
    margin-top: 10px;
    display: block;
    text-align: center;
    &.title {
      font-family: $font-serif;
      font-size: 18px;
      line-height: 18px;
    }
  }

  p a {
    text-align: left;
  }

  @media #{$bootstrap_phones_to_tablets} {
    width: 50% !important;
  }

  @media #{$bootstrap_portrait_tablets} {
    width: 50% !important;
  }

  @media #{$bootstrap_phones} {
    width: 100% !important;
    float: none !important;
  }
}

// Rotating Hero Spotlight & Rotating Hero Spotlight w/Read More Styles.

// Since we have taken to using CSS positioning as opposed to floats we
// need to pull the tab navigation to the top, but lets push them back
// and hide them on the mobile view since space is limited.
.pane-bundle-college-spotlight .ui-tabs-nav {
  z-index: 10;
  position: absolute;
  top: 0.7em;
  right: 0.7em;
  @media #{$bootstrap_phones_to_tablets} {
    display: none;
    z-index: -10;
  }
}

div.pane-bundle-college-spotlight {
  // These setup the 'frame' around the spotlight, and turn off the frame on
  // displays with a max-width of 767px (landscape phones and portrait tablets)
  padding: 15px 15px 15px 15px;
  background-color: #fdf8f2;
  @include box-shadow(0px 5px 10px rgba(50,50,50,0.5));
  position: relative;
  @media #{$bootstrap_phones_to_tablets} {
    padding: 0px;
  }

  // Each spotlight item is wrapped in a wrapper, we limit the wrapper's size
  // and set the background color.

  div.college-spotlight-wrapper {
    height: 240px;
    max-height: 240px;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    background-color: $collegebrown;
    @media #{$bootstrap_phones_to_tablets} {
      height: auto;
    }
    @media #{$bootstrap_portrait_tablets} {
      height: 215px;
    }

    div.college-spotlight-info {
      @include box-sizing(border-box);
      background-color: $collegebrown;
      width: 33%;
      height: 100%;
      padding: 7px;
      z-index: 2;
      position: relative;
      @media #{$bootstrap_phones_to_tablets} {
        width: 100%;
        height: auto;
      }

      h2.college-spotlight-title a, h2.college-spotlight-title {
        color: $collegeyellow;
        font-size: 40px;
        line-height: 35px;
        text-shadow: 1px 1px 1px #000;
        padding-bottom: 10px;
        font-weight: normal;
        @media #{$bootstrap_phones_to_tablets}, #{$bootstrap_portrait_tablets} {
          margin: 0px auto;
          width: 90%;
          padding: 5px 0px;
          font-size: 22px;
          line-height: 22px;
        } // ./ends @media
      } // ./ends h2 and h2 a styles

      p {
        color: #fff;
        padding: 0px;
        margin: 0px;
        overflow: hidden;
        text-overflow: clip;
        @media #{$bootstrap_portrait_tablets} {
          font-size: 12px;
          line-height: 15px;
        }
        @media #{$bootstrap_phones_to_tablets} {
          display: none;
        } // ./ends @media
      } // ./ends p

      a.college-spotlight-readmore {
        position: absolute;
        right: 15px;
        bottom: 15px;
        color: #fff;
        &:after {
          // CSS Content doesn't support named entities, so use the unicode
          content: ' \000BB';
        }
        @media #{$bootstrap_phones_to_tablets} {
          position: absolute;
          top: 0px;
          left: 0px;
          text-indent: -9999px;
          height: 100%;
          width: 100%;
        } // ./ends @media
      } // ./ends <a>
    } // ./ends spotlight-info

    div.college-spotlight-image-wrapper {
      overflow: hidden;
      position: absolute;
      top: 0px;
      right: 0px;
      width: 67%;
      height: 240px;

      @media #{$bootstrap_phones_to_tablets} {
        width: 100%;
        position: relative;
      }
    }
  }
}

// Hero Spotlight styles.
 div.college-spotlight-hero {
  position: relative;
  width: 100%;
  @include box-shadow(0px 3px 3px rgba(50,50,50,0.75));
  div.college-spotlight-hero-inset {
    position: absolute;
    bottom: 0px;
    h2, h2 a {
      padding: 0px 10px;
      font-size: 40px;
      color: white;
      font-family: $font-serif;
      text-shadow: 1px 1px 1px #000;
    }
    div.college-spotlight-hero-description {
      padding: 10px 10px 30px;
      background: rgba(255,255,255,0.6);
      min-width: 50%;
      max-width: 500px;
    }
  }
 }
