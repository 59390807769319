// Some styles that will really only be used on the main CAFE Home Page, but I
// am trying to avoid sub-theming. We could probably optimize this in some way
// but we will circle back to that in the future. For now we are just adding
// a raw HTML widget to the home page and adding the .college-mission class to
// the first div.

// Since the #college-mission section is using a raw html widget, we should
// update it to use Bootstrap 3 selectors.

#college-mission {
  margin: 0px -5px -5px -5px;

  @media #{$bootstrap_phones_to_tablets} {
    margin-left: 0px;
    margin-right: 0px;
    div[class*="col-sm-3"] {
      width: 90%;
    }

    div.row:nth-child(2), div.row-fluid:nth-child(2) {
      background: #896c4a;
      background: -moz-linear-gradient(top, #896c4a 0%, #493505 54%);
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#896c4a), color-stop(54%, #493505));
      background: -webkit-linear-gradient(top, #896c4a 0%, #493505 54%);
      background: -o-linear-gradient(top, #896c4a 0%, #493505 54%);
      background: -ms-linear-gradient(top, #896c4a 0%, #493505 54%);
      background: linear-gradient(to bottom, #896c4a 0%, #493505 54%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#896c4a', endColorstr='#493505', GradientType=0);
    }
  }

  .row .col-sm-3.insets.items, .row-fluid .span3.insets.items {
    background-color: #fff;
    margin-left: 0px;
    padding: 10px;
    padding-bottom: 0px;
    @media #{$bootstrap_phones_to_tablets} {
      padding: 10px;
    }
  }

  .college-mission-header {
    background: #896c4a;
  }

  .college-mission-info {
    background: #896c4a;
    background: -moz-linear-gradient(top, #896c4a 0%, #493505 54%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#896c4a), color-stop(54%, #493505));
    background: -webkit-linear-gradient(top, #896c4a 0%, #493505 54%);
    background: -o-linear-gradient(top, #896c4a 0%, #493505 54%);
    background: -ms-linear-gradient(top, #896c4a 0%, #493505 54%);
    background: linear-gradient(to bottom, #896c4a 0%, #493505 54%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#896c4a', endColorstr='#493505', GradientType=0);
  }

  h2 {
    font-family: $font-serif;
    font-weight: normal;
    @media #{$bootstrap_phones_to_tablets} {
      margin-bottom: 0px;
    }
  }

  h3 {
    font-family: $font-serif;
    font-weight: normal;
  }

  h2, p {
    padding-left: 15px;
    padding-right: 15px;
  }

 .college-mission-info p {
    color: #fff;
  }

  .row .col-sm-3.insets.items h3, .row-fluid .span3.insets.items h3 {
    margin: 0px;
    line-height: 40px;
  }

  .row .col-sm-3.insets.items ul, .row-fluid .span3.insets.items ul {
    margin-left: 0px;
    padding-left: 0px;
    list-style: none;
  }

  .row .col-sm-3.insets.items p, .row-fluid .span3.insets.items p {
    margin: 0px 0px 10px;
    padding: 0px;
  }
}

.pane-content #college-mission.container {
  padding-bottom: 0px;
  @media #{$bootstrap_phones_to_tablets} {
    padding-left: 0px;
    padding-right: 0px;
  }
}
