.file-college-left {
  float: left;
  margin: 10px 10px 10px 0px;
}

.file-college-right {
  float: right;
  margin: 10px 0px 10px 10px;
}

// Here we are just stealing the standard clearfix hacks and applying them to
// the fieldable panel pane elements. probably a bad idea.
.fieldable-panels-pane:before, .fieldable-panels-pane:after {
  display: table;
  content: "";
  line-height: 0;
}

.fieldable-panels-pane:after {
  clear: both;
}
