// Styles for Views
// -----------------------------------------------------------------------------

ul, ol {
  list-style: none;
}
div.field ul {
  list-style-type: disc;
}
.field ul, .field ol {
  list-style: inherit ;
}

.cafe-gallery img {
  margin: 5px 3px 5px 3px;
  max-height: 65%;
  width: auto;
}

.cafe-gallery-view img {
  margin: 13px 0px 13px 0px;
  max-height: 75%;
  width: auto;
}
