/**
 * @file
 * Provides theme specific overrides for Form CSS
 */

// Radix for whatever reason overrides Bootstrap to set a fixed height
// on select lists with the multiple attribute or with a specified size.
// This overrides this back to default behavior.

select[multiple],
select[size] {
  height: auto;
}

// While we are at it, lets tackle OS X 10.7+ annoying habit of hiding
// scrollbars automatically by default. In Chrome this makdes the scrollbars
// invisible in iframes, textareas, and multiiple select boxes.
//
// Based on the following StackOverlow answer http://stackoverflow.com/a/7855592

select[multiple], select[size], textarea, iframe {
  overflow-y: auto;
  border: 1px solid black;
}

select[multiple]::-webkit-scrollbar,
select[size]::-webkit-scrollbar,
textarea::-webkit-scrollbar,
iframe::-webkit-scrollbar {
  -webkit-appearance: none;
}

select[multiple]::-webkit-scrollbar:vertical,
select[size]::-webkit-scrollbar:vertical,
textarea::-webkit-scrollbar:vertical,
iframe::-webkit-scrollbar:vertical {
  width: 11px;
}

select[multiple]::-webkit-scrollbar:horizontal,
select[size]::-webkit-scrollbar:horizontal,
textarea::-webkit-scrollbar:horizontal,
iframe::-webkit-scrollbar:horizontal {
  height: 11px;
}

select[multiple]::-webkit-scrollbar-thumb,
select[size]::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb,
iframe::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;

  /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, .5);
}

// Style override for URL field on landing page creation
#panopoly_pages_landing_page_wizard #edit-path {
  display: inline-block;
  width: auto;
}

// Fix Replacement Patterns not expandable
#views-ui-config-item-form fieldset {
  .form-control {
    height: auto;
  }
  .collapsible {
    float: none;
  }
}
#views-ui-config-item-form .form-control {
  height: auto;
}

// Search bar in admin_menu should not be 100% wide
#admin-menu {
  .admin-menu-search {
    input {
      height: auto;
    }
    width: auto;
  }
}
