// @file
// SASS partials; General resets and includes.

@import "../fonts/cafe/socialico/stylesheet.css";
@import "../fonts/cafe/EBGaramond/stylesheet.css";

// The styles are rendering correctly when viewing content, but for some reason
// are not appearing when using the editor.  Let's fix that.
#tinymce ol {
  list-style-type: decimal;
}
#tinymce ul {
  list-style-type: disc;
}

// Just places a border around the main content.  Should be a box-shadow but
// we are encoruntering some ghosting from where box-shadow bleeds out of the
// top and bottom so this is a temporary work around.
.cafe-page-wrap {
  background-color: $collegewhite;
}

span {
  &.cafe-large-text {
    font-size: large;
  }
  &.cafe-xlarge-text {
    font-size: x-large;
  }
  &.cafe-xxlarge-text {
    font-size: xx-large;
  }
}

// This makes the close icon on Panopoly modal windows standout a little more
// clearly.
.close {
  @include opacity(1.0);

  &:hover, &:focus {
    @include opacity(0.5);
  }
}

a.title {
  font-size: 30px;
  color: $collegewhite;
  font-variant: small-caps;
  font-family: $font-serif;
}

// Icons
[data-icon]:before {
  font-family: "social";
  /* BYO icon font, mapped smartly */
  content: attr(data-icon);
  font-size: 28px;
  speak: none;
}

.icon-alone {
  padding: 11px;
  display: inline-block;
  /* Chrome 19 was weird with clickability without this */
}

.screen-reader-text {
  /* Reusable, toolbox kind of class */
  position: absolute;
  top: -9999px;
  left: -9999px;
}

body.html {
  background: #fff;
  padding: 0px;
}

// Default image styling
img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

div#content .container, div[id*="main-wrapper"] > div#main.main.container > div#content {
  // background-color: $collegewhite;
  // @include box-sizing(border-box);
  // padding-left: 5px;
  // padding-right: 5px;
  // padding-bottom: 5px;
  // @media #{$landscape-phone} {
  //   padding-left: 2px;
  //   padding-right: 2px;
  // }
}

.news .row{
  margin-left:0px;
}

// I am guessing that Chrome/Webkit/Firefox all default back to the base styles
// when attempting to inehrit a declaration from an element type that doesn't
// support it, while it seems that Internet Explorer takes you at your
// word and therefore inherits nothing, meaning the last valid declaration was
// still to turn off the list-style-type. These overrides target UL and OL tags
// that are contained in a fielded area.  We had to place them here bedcause
// conditional comments are not supported in Internet Explorer 10 and above, so
// we couldn't just target them with conditional CSS as originally planned.
// (J.R. Jenkins)

div.field ul {
  list-style-type: disc;
}

div.field ol {
  list-style-type: decimal;
}

.pane-content .view-news .item-list ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

.pane-content .view-news .item-list ul li {
  margin: 0px;
  padding: 15px 0px;
}

.shadow-box-lower-left, .shadow-box-lower-right, .shadow-box-upper-left, .shadow-box-upper-right, .black-border {
  box-sizing: border-box;
  margin: 3px;
  padding: 10px;
  border: 1px solid rgba(50,50,50,0.5);
}

.cafe-home-page {
  /* Added to prevent breaking Bootstrap widths */
  box-sizing: border-box;
  /* From CAFE Home */
  -webkit-box-shadow: 0px 10px 26px rgba(50,50,50,0.09);
  -moz-box-shadow: 0px 10px 26px rgba(50,50,50,0.09);
  box-shadow: 0px 10px 26px rgba(50,50,50,0.09);
  border: none;
}

/* Shadow Lower Left */
.shadow-box-lower-left {
  -webkit-box-shadow: -3px 3px 3px 1px rgba(50,50,50,0.09);
  -moz-box-shadow: -3px 3px 3px 1px rgba(50,50,50,0.09);
  box-shadow: -3px 3px 3px 1px rgba(50,50,50,0.09);
}

/* Shadow Lower Right */
.shadow-box-lower-right {
  -webkit-box-shadow: 5px 5px 3px 1px rgba(50,50,50,0.09);
  -moz-box-shadow: 5px 5px 3px 1px rgba(50,50,50,0.09);
  box-shadow: 5px 5px 3px 1px rgba(50,50,50,0.09);
}

/* Shadow Upper Left */
.shadow-box-upper-left {
  -webkit-box-shadow: -5px -5px 3px 1px rgba(50,50,50,0.09);
  -moz-box-shadow: -5px -5px 3px 1px rgba(50,50,50,0.09);
  box-shadow: -5px -5px 3px 1px rgba(50,50,50,0.09);
}

/* Shadow Upper Right */
.shadow-box-upper-right {
  -webkit-box-shadow: 5px -5px 3px 1px rgba(50,50,50,0.09);
  -moz-box-shadow: 5px -5px 3px 1px rgba(50,50,50,0.09);
  box-shadow: 5px -5px 3px 1px rgba(50,50,50,0.09);
}

/* Solid Black */
.black-border {
  border-radius: 0px 0px 0px 0px;
  -moz-border-radius: 0px 0px 0px 0px;
  -webkit-border-radius: 0px 0px 0px 0px;
  border: 1px solid #000000;
}

// Container override for small devices
@media #{$bootstrap_phones_to_tablets} {
  .container {
    width: auto;
  }
}

// Because our main div uses a Bootstrap 3 container class, let's remove the padding
#main {
  padding-left: 0;
  padding-right: 0;
}

// A couple of admin pages use buttons that, upon hover, are displayed with white text on a light gray background. Let's fix that.
.nav.nav-pills.action-links > li > a {
  &:hover,
  &:focus {
    background: darken($brand-primary, 5%);
  }
}

// Display featured images with some padding and a shadow
.field.field-type-image .field-item {
  border: 1px solid #DDD;
  padding: 5px;
  background: #FFFFFF;
  display: table;
  -webkit-border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  border-radius: 4px 4px 4px 4px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
  overflow: hidden;
  margin-bottom: 10px;
  img {
    margin: 0;
    padding: 0;
  }
}
.field img.panopoly-image-full, .field img.panopoly-image-half, .field img.panopoly-image-quarter {
  max-width: 100%;
  width: auto;
  height: auto;
  float: none;
  margin: 0;
}

// Bootstrap overrides
// Change base font for buttons to 16px
.btn, .media-widget > a {
  font-size: 16px;
}
// Maintain usability for some labels across sites
.label {
  display: inline-block;
  &.label-important {
    background-color: #b94a48;
  }
}

// Content section separators
.separator {
  color: $gray_dark;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  border-top: 1px solid $gray_light2;
  margin: 6em 0;
  font-size: 18px;
  > div {
    display: block;
    width: auto;
    margin-top: -10px;
    line-height:18px;
    > span {
      background: #fff;
    }
  }
}

// Large content area headings
.section-heading {
  display: block;
  font-family: $font-sans-serif;
  text-align: center;
  margin: 1em 0;
  color: $blue_dark;
  line-height: 1.5;
  font-size: 2.2em;
  font-weight: 100;
}
  @media #{$bootstrap_phones_to_tablets} {
    .section-heading {
      font-size: 22px;
    }
  }

// Hero images should not show borders/padding
.hero-image .field-items > .field-item {
  border: none;
  padding: 0;
  border-radius: 0;
  margin-bottom:0;
}

// Remove the underlines for hyperlinks in News Blocks, CtA Buttons, and Tabbed Blocks

// 4-Column news block styling
.news-block {
  text-align: center;
  > div {
    > div {
      width: 100%;
      height: 230px;
      border-radius: 1px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      margin-bottom: 1em;
    }
    > a > img {
      border-radius: 1px;
      margin-bottom: 1em;
    }
    > .article-title {
      font-size: 18px;
      > a {
        text-decoration: none !important;
        font-weight: 400 !important;
      }
    }
    &:last-of-type {
      border-left: 1px solid #eee;
      ul {
        padding: 0;
        margin: 0;
        > li {
          margin-bottom: 1.5em;
          text-align: left;
          line-height: 1.2;
          list-style-type: none;
          div, img {
            display: none;
          }
        }
      }
      @media #{$bootstrap_phones_to_tablets} {
        ul {
          border-top: 1px solid #eee;
          padding-top: 1em;
          margin-top: 1em;
          margin-left:1.5em;
          > li {
            margin-bottom: 1em;
            list-style-type: circle;
          }
        }
      }
    }
    @media #{$bootstrap_phones_to_tablets} {
      margin-bottom: 2em;
    }
    @media #{$bootstrap_portrait_tablets} {
      margin-bottom: 1em;
    }
  }
}

// Call-to-Action buttons with FontAwesome icons
.cta-button {
  text-align: center;
  border-right: 1px solid $gray_light2;
  .cta-heading {
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 1.1;
    color: $blue_medium;
  }
  i.fa {
    color: $blue_medium;
  }
  @media #{$bootstrap_phones_to_tablets} {
    border-right: none;
    border-bottom: 1px solid $gray_light2;
    padding-bottom: 1em;
    margin-bottom: 1em;
  }
  &:last-of-type {
    border: none;
  }
}

// Tabbed block stying
.tab-block {
  .nav-tabs.nav-justified {
    border-bottom: 1px solid $gray_light2;
    margin-bottom: 0;
    li {
      a {
        border: none;
        border-radius: 0;
        padding: 1em 0.5em;
      }
      &.active > a {
        background: $gray_light3;
        color: $blue_dark;
        &:hover,
        &:active,
        &:focus {
          border: none;
        }
      }
    }
  }
  .tab-content {
    padding: 2em 4em 4em;
    background: $gray_light3;
    border-top: 0;
    h2 {
      color: $blue_medium;
    }
    a.btn {
      display: inline-block;
      margin-bottom: 0.5em;
    }
    @media #{$bootstrap_phones_to_tablets} {
     padding: 1em 1.5em;
    }
    .tab-block-content-heading {
      display: inline-block;
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 30px;
      font-weight: 700;
      line-height: 1.1;
      color: $blue_medium;
    }
  }
}

// Override for media-video  panes
.media-vimeo-video, .media-youtube-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
}

// Medium blue with transparency--used over the banner image in the header,
// but may be useful elsewhere at some point
.transparent-blue {
  background-color: rgba(lighten($blue_medium, 5%), 0.6);
}

// Month/Year select boxes in date-picker fields are unreadable (white text on white bg). Let's fix that.
.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year {
  background-color: $wildcat_white;
  color: $wildcat_black;
}

// On some admin pages, the table header remains visible as the user scrolls down. Without a z-index, some
// form elements such as checkboxes continue to display even though they should be behind the header.
.sticky-header {
  z-index: 1;
}

// Make TinyMCE responsive - March 2018
// Also adjust some properties for better formatting with Cirkuit skin for TinyMCE
table.mceToolbar {
  display: table;
}
.mceToolbar > tbody > tr > td {
  display: table-row;
  float: left;
  height: 27px;
}

// Quick workaround for responsive tables' overflow-x hiding columns on some pages
.table-responsive > table > tbody > tr > td {
  &.views-field-title {
    word-break: break-word;
  }
  &.views-field-filename {
    word-break: break-all;
  }
}

// Add word-break to captions, in case the text is too long for the image
div.caption p.caption-text {
  word-break: break-word;
}

// Add some styling to Events Featured Image captions
// TODO: Check on possibility of making one universal .caption class
.node-event .caption {
  padding: 18px;
  background-color: #f0efee;
}

// Restyle Bootstrap's accordion
a.accordion-toggle {
	display: block;
  padding: 10px 15px;
  margin: -10px -15px;
  text-decoration: none !important;
  &:before {
    content:"\e114";
    font-family:'Glyphicons Halflings';
    padding-right: 5px;
    font-size: 12px;
  }
  &.collapsed {
    &:before {
      content:"\e080";
    }
  }
}
.panel-group{
	.panel, .panel-heading {
	 	border:none !important;
	}
	.panel-body {
		border: 1px solid $panel-default-heading-bg !important;
	  border-width: 0 1px 1px 1px !important;
	}
	.panel-heading a, a.panel-heading {
		outline: 0;
	}
}

// Fix for element-invisible class sometimes causing a horizontal scroll bar
.element-invisible {
  width: 1px;
  word-wrap: normal;
}

// Remove title from Event display
.node-type-event #page-header .page-header {
  display: none;
}

// Carousel caption-below formatting
.caption-below {
  background-color: $gray_light2;
  padding: 10px;
  margin-bottom: 20px;
  text-align: center;
}
